<template>
  <div class="content-wrapper">
    <div class="carousel-content" v-if="topList.length">
      <!-- <el-image fit="cover" :src="bannerImg"></el-image> -->
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item in topList" :key="item.imgUrl">
          <el-image
            v-if="item.imgUrl"
            class="carousel-image"
            fit="cover"
            :src="item.imgUrl"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="zuopin-content">
      <div
        class="item"
        v-for="(item, index) in centerList"
        :key="index"
        @click="toDetail(item, index)"
      >
        <div class="tip" v-if="item.tip">{{ item.tip }}</div>
        <el-image v-if="item.imgUrl" :key="index" :src="item.imgUrl"></el-image>
        <!-- <div class="title">设计共生：2021中国设计智造大奖</div> -->
        <div class="title">{{ item.remark }}</div>
      </div>
    </div>
    <div class="news-content">
      <div
        class="item"
        v-for="(item, index) in bottomList"
        :key="index"
        @click="toDetail(item, index)"
      >
        <el-image v-if="item.imgUrl" :key="index" :src="item.imgUrl"></el-image>
        <div class="title">{{ item.remark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Carousel, CarouselItem, Image } from "element-ui";
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Image.name, Image);

export default {
  name: "content",
  props: {
    activeIndex: {
      type: String,
      default: "0",
    },
    bannerList: null,
  },
  mounted() {},
  computed: {},
  watch: {
    bannerList: {
      handler() {
        this.topList = this.bannerList.top || [];
        this.centerList = this.bannerList.center || [];
        this.bottomList = this.bannerList.buttom || [];
      },
    },
  },
  data() {
    return {
      newsActiveIndex: 1,
      topList: [],
      centerList: [],
      bottomList: [],
    };
  },
  methods: {
    toDetail(item) {
      if (item.type === 1) {
        // 新闻
        this.$router.push(`/news/${item.directUrl}`);
      } else if (item.type === 2) {
        // 作品
        this.$router.push(`/news/works/${item.directUrl}`);
      } else if (item.type === 3) {
        // 外部链接
        window.open(item.directUrl);
      }
    },
    handleSelect(key) {
      this.newsActiveIndex = key;
    },
  },
};
</script>

<style lang="less" scoped>
.content-wrapper {
  margin: 10px 0 36px;

  .news {
    margin: 0 0px 2.8vw;
    header {
      margin: 1.4vw 0px;
      text-align: left;
      .button {
        padding: 0.277vw 0.7vw;
        margin-right: 0.42vw;
        cursor: pointer;
        font-size: 1vw;
        background: #fff;
        color: #606266;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        &:hover {
          background-color: unset;
          color: #1a2043;
          border: 1px solid #1a2043;
        }
        &.active {
          background-color: unset;
          color: #1a2043;
          border: 1px solid #1a2043;
        }
      }
    }
  }

  .title {
    color: #515151;
    font-size: 1vw;
    text-align: left;
    margin: 0.5vw 0 2vw;
  }
  /deep/.carousel-content {
    margin-bottom: 2.5vw;
    .carousel-image {
      width: 72vw;
      height: 31vw;
    }
    .el-image {
      border-radius: 0.7vw;
    }
    .el-carousel__container {
      height: 30vw;
    }
    .el-carousel__item {
      // background-color: #99a9bf;
      border-radius: 10px;
    }
  }
  .zuopin-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 72vw;
    .item {
      width: 35vw;
      // height: 25vw;
      position: relative;
      .tip {
        border-radius: 0.56vw 0 0.56vw 0;
        position: absolute;
        left: 0;
        top: 0;
        background: #e25151;
        color: #fff;
        z-index: 10;
        font-size: 0.83vw;
        padding: 2px 4px;
      }
      .el-image {
        border-radius: 8px;
      }
    }
  }
  .news-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item {
      width: 24%;
      margin-right: 1%;
      .el-image {
        border-radius: 8px;
      }
    }
  }
}
</style>
